import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { Link } from "gatsby"
import CheckLanguage from "../utils/checkLangauge"
import { useSelector, useDispatch } from "react-redux"
import { closeMenuMobile } from "../store/menuSlice"

const ModuleWrapper = styled.div`
  width: 100px;
  margin-left: 100px;
  @media (max-width: 1025px) {
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const LanguagesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledText = styled.p`
  font-family: "Lato";
  font-size: 14px;
  line-height: 10px;
  letter-spacing: 5px;
  text-transform: lowercase;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: relative;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 1025px) {
    font-size: 12px;
  }
  &.info {
    &:hover {
      &::after {
        content: "";
        position: absolute;
        width: 105%;
        height: 2px;
        left: -5%;
        background-color: white;
        z-index: -1;
        top: 1px;
        bottom: 0;
        margin: auto;
      }
    }
  }
`

const StyledLink = styled(Link)`
  font-family: "Lato";
  font-size: 14px;
  line-height: 10px;
  letter-spacing: 5px;
  text-transform: lowercase;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: relative;
  z-index: 2;
  cursor: pointer;
  &.activeLanguage {
    &::after {
      content: "";
      position: absolute;
      width: 105%;
      height: 2px;
      left: -5%;
      background-color: white;
      z-index: -1;
      top: 1px;
      bottom: 0;
      margin: auto;
    }
  }
`

const ChangeLanguage = ({ languages }) => {
  const activePage = useSelector(state => state.menu.activePage)
  const activeLanguage = useSelector(state => state.menu.activeLanguage)
  const dispatch = useDispatch()
  const [languagesVisibility, setLanguagesVisibility] = useState(false)
  return (
    <ModuleWrapper onClick={() => setLanguagesVisibility(!languagesVisibility)}>
      {!languagesVisibility && (
        <StyledText className="info">{languages}</StyledText>
      )}
      {languagesVisibility && (
        <LanguagesWrapper>
          <StyledLink
            onClick={() => dispatch(closeMenuMobile())}
            className={activeLanguage === "en" ? "activeLanguage" : ""}
            to={"/" + activePage}
          >
            en
          </StyledLink>
          <StyledLink
            onClick={() => dispatch(closeMenuMobile())}
            className={activeLanguage === "es" ? "activeLanguage" : ""}
            to={"/es/" + activePage}
          >
            es
          </StyledLink>
          <StyledLink
            onClick={() => dispatch(closeMenuMobile())}
            className={activeLanguage === "pl" ? "activeLanguage" : ""}
            to={"/pl/" + activePage}
          >
            pl
          </StyledLink>
        </LanguagesWrapper>
      )}
    </ModuleWrapper>
  )
}

export default ChangeLanguage
