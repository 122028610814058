import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import GlobalStyle from "../theme/GlobalStyle"
import { Theme } from "../theme/Theme"
import Menu from "../components/menu/Menu"

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  max-height: 100vh;
  @media (max-width: 1023px) {
    max-height: unset;
    height: auto;
  }
`

const Layout = ({ children, data }) => {
  return (
    <>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        {/* <Menu /> */}
        <StyledWrapper>{children}</StyledWrapper>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// export const query = graphql`
//   query MyQuery {
//     datoCmsMenu {
//       _allMenuitemLocales {
//         locale
//       }
//       menuitem {
//         pagelink {
//           __typename
//           ... on DatoCmsContact {
//             pagename
//           }
//           ... on DatoCmsWork {
//             pagename
//           }
//           ... on DatoCmsHomepage {
//             id
//           }
//           ... on DatoCmsAbout {
//             pagename
//           }
//         }
//       }
//     }
//   }
// `
