import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Paragraph from "../typography/Paragraph"
import Logo from "../../assets/logo.svg"
import LogoHover from "../../assets/logo-hover.svg"
import LogoHoverGreen from "../../assets/logo-hover-green.svg"
import { useSelector, useDispatch } from "react-redux"
import { openMenu, openMenuMobile } from "../../store/menuSlice"
import aboutHover from "../../assets/hovery-menu/about.svg"
import workHover from "../../assets/hovery-menu/work.svg"
import contactHover from "../../assets/hovery-menu/contact.svg"
import MenuMobile from "./MenuMobile"
import ChangeLanguage from "../ChangeLanguage"
import CheckLanguage from "../../utils/checkLangauge"

const StyledModuleWrapper = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
`

const StyledContentWrapper = styled.div`
  display: flex;
  margin: 60px 70px;
  margin-bottom: 0;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1440px) {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    margin: 40px;
  }

  /* @media (max-width: 1020px) {
    display: none;
  } */
  /* 
  @media (max-width: 850px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 768px) {
    margin: 32px;
  }

  @media (max-width: 500px) {
    margin: 32px 16px;
  } */
`

const StyledMenuText = styled.h3`
  font-family: "Lato";
  font-size: 14px;
  line-height: 10px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: relative;
  z-index: 2;
  &.margin {
    margin-left: 100px;
    @media (max-width: 767px) {
      margin-left: 20px;
    }
  }
  &.white {
    color: white;
  }
  &.social-media {
    text-transform: lowercase;
    &:hover {
      &::after {
        content: "";
        position: absolute;

        width: 105%;
        height: 2px;
        left: -5%;
        background-color: white;
        z-index: -1;
        top: 1px;
        bottom: 0;
        margin: auto;
        /* &::after {
          content: "";
          position: absolute;
          color: black;
          width: 105%;
          height: 3px;
          z-index: 5;
        } */
      }
      &::before {
        /* content: "";
        position: absolute;
        color: ${({ theme }) => theme.colors.green};
        bottom: -7px;
        left: 0;
        right: 5px;
        margin: 0 auto;
        z-index: -1; */
      }
    }
  }

  &:hover {
    &::after {
      content: "${({ name }) => name}";
      position: absolute;
      color: ${({ isWhite }) => (isWhite ? "#4B4B4B" : "#eee")};
      top: -7px;
      left: 0;
      right: 5px;
      margin: 0 auto;
      z-index: -1;
      transform: translateX(4px);
      width: max-content;
    }
    &::before {
      content: "${({ name }) => name}";
      position: absolute;
      color: ${({ theme }) => theme.colors.green};
      bottom: -7px;
      left: 0;
      right: 5px;
      margin: 0 auto;
      z-index: -1;
      transform: translateX(4px);
      width: max-content;
    }
  }
`

const StyledLogo = styled.img`
  width: 34px;
  height: auto;
  &.white {
    filter: invert(100%) sepia(0%) saturate(7482%) hue-rotate(97deg)
      brightness(94%) contrast(105%);
  }
`

const StyedLogoWrapper = styled.div`
  position: relative;
  margin-right: 64px;
  z-index: 8;
  @media (max-width: 767px) {
    margin-right: 30px;
  }

  &.subpage {
    &:hover {
      img {
        opacity: 0;
      }
      &::after {
        content: "";
        background-image: url(${LogoHoverGreen});
        background-repeat: no-repeat;
        background-size: cover;
        width: 450px;
        height: 60px;
        position: absolute;
        top: -14px;
        left: -50px;
        bottom: 0;
      }
    }
    @media (max-width: 1030px) {
      &:hover {
        img {
          opacity: 1;
        }
        &::after {
          content: "";
          background-image: url();
        }
      }
    }
  }

  &.home {
    &:hover {
      img {
        opacity: 0;
      }
      &::after {
        content: "";
        background-image: url(${LogoHover});
        background-repeat: no-repeat;
        background-size: cover;
        width: 450px;
        height: 60px;
        position: absolute;
        top: -14px;
        left: -50px;
        bottom: 0;
      }
    }
    @media (max-width: 1030px) {
      &:hover {
        img {
          opacity: 1;
        }
        &::after {
          content: "";
          background-image: url();
        }
      }
    }
  }
`

const StyledMenuWrapper = styled.div`
  display: flex;
`

const StyledSocialMediaWrapper = styled.div`
  display: flex;
  transition: opacity 0.5s ease-in-out;
  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
  @media (max-width: 1030px) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  position: relative;
  &.active {
    /* &:before {
      content: "";
      background-image: url("${({ pattern }) => pattern}");
      background-size: cover;
      width: 50px;
      height: 50px;
      position: absolute;
      right: 7%;
      bottom: 130%;
    }
    &.about {
      &:before {
        right: 19% !important;
      }
    }
    &.work {
      &:before {
        right: 7% !important;
      }
    }
    &.contact {
      &:before {
        right: 14% !important;
      }
    } */
    /* &.white {
      &:before {
        filter: invert(100%) sepia(4%) saturate(5175%) hue-rotate(211deg)
          brightness(104%) contrast(100%);
        content: "";
        background-image: url("${({ pattern }) => pattern}");
        background-size: cover;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 7%;
        bottom: 130%;
      }
    } */
    h3 {
      position: relative;
      &::after {
        content: "${({ name }) => name}";
        position: absolute;
        color: ${({ isWhite }) => (isWhite ? "#4B4B4B" : "#eee")};
        top: -7px;
        left: 0;
        right: 5px;
        margin: 0 auto;
        z-index: -1;
        transform: translateX(4px);
        width: max-content;
      }
      &::before {
        content: "${({ name }) => name}";
        position: absolute;
        color: ${({ theme }) => theme.colors.green};
        bottom: -7px;
        left: 0;
        right: 5px;
        margin: 0 auto;
        z-index: -1;
        transform: translateX(4px);
        width: max-content;
      }
    }
  }
  &.about {
    &:hover {
      &:before {
        right: 19% !important;
      }
    }
  }
  &.work {
    &:hover {
      &:before {
        right: 7% !important;
      }
    }
  }
  &.contact {
    &:hover {
      &:before {
        right: 14% !important;
      }
    }
  }
  &:hover {
    &:before {
      content: "";
      background-image: url("${({ pattern }) => pattern}");
      background-size: cover;
      width: 50px;
      height: 50px;
      position: absolute;
      right: 7%;
      bottom: 130%;
    }
  }
  &.white {
    &:hover {
      &:before {
        filter: invert(100%) sepia(4%) saturate(5175%) hue-rotate(211deg)
          brightness(104%) contrast(100%);
        content: "";
        background-image: url("${({ pattern }) => pattern}");
        background-size: cover;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 7%;
        bottom: 130%;
      }
    }
  }

  @media (max-width: 1030px) {
    display: none;
  }
`

const StyledCrossWrapper = styled.div`
  position: fixed;
  z-index: 110;
  top: 23px;
  left: 80px;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  display: none;
  div {
    transform: rotate(
      ${({ isMenuMobileOpen }) => (isMenuMobileOpen ? "45" : "0")}deg
    );
  }

  @media (max-width: 1024px) {
    display: flex;
    top: 58px;
    right: 50px;
    left: unset;
    margin: 0;
    &::after {
      content: "";
      position: absolute;
      width: 63px;
      height: 2px;
      background-color: ${({ isWhite }) => (isWhite ? "white" : "black")};
      left: 10px;
      top: 0;
    }
  }
`

const StyledCross = styled.div`
  width: 24px;
  height: 2px;
  position: relative;
  background-color: ${({ theme, isMenuOpen }) =>
    isMenuOpen ? "black" : theme.colors.green};
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  &::after {
    content: "";
    width: 24px;
    height: 2px;
    position: absolute;
    background-color: ${({ isWhite }) => (isWhite ? "white" : "black")};
    transform: rotate(90deg);
  }
`

const StyledRightCross = styled.div`
  position: absolute;
  right: 12px;
  width: 24px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.green};
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 100;
  &::after {
    content: "";
    width: 24px;
    height: 2px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.green};
    transform: rotate(90deg);
  }
  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
`

const StyledLeftCross = styled.div`
  position: absolute;
  left: 12px;
  width: 24px;
  height: 2px;
  background-color: white;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 100;
  &::after {
    content: "";
    width: 24px;
    height: 2px;
    position: absolute;
    background-color: white;
    transform: rotate(90deg);
  }
  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
`

const StyledDesktopWrapper = styled.div`
  display: block;
  @media (max-width: 1020px) {
    display: none;
  }
`

const StyledMobileBg = styled.div`
  position: fixed;
  width: 100vh;
  height: 80px;
  background: ${({ isWhite }) => (isWhite ? "#1d1d1d" : "white")};
  z-index: 10;
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`

const Menu = ({ data, isWhite, isHome, globalData }) => {
  const isMenuOpen = useSelector(state => state.menu.isMenuOpen)
  const isMenuMobileOpen = useSelector(state => state.menu.isMenuMobileOpen)
  const activePage = useSelector(state => state.menu.activePage)
  const activeLanguage = useSelector(state => state.menu.activeLanguage)

  const dispatch = useDispatch()

  const socialMediaData = globalData?.socialmedia || []
  const pagesData = data?.menuitem || []
  const languages = globalData?.languages || ""

  let linkArray = []
  let aboutObject = ""
  let workObject = ""
  let contactObject = ""

  pagesData.map(item => {
    const pageLinkArray = item?.pagelink || []
    return (linkArray = linkArray.concat(pageLinkArray))
  })

  linkArray.map(item => {
    switch (item?.__typename) {
      case "DatoCmsAbout":
        aboutObject = item
        break
      case "DatoCmsWork":
        workObject = item
        break
      case "DatoCmsContact":
        contactObject = item
        break
    }
  })

  return (
    <>
      {isMenuMobileOpen && (
        <MenuMobile
          data={data}
          globalData={globalData}
          isHome={isHome}
          isWhite={isWhite}
        />
      )}

      {!isHome && <StyledMobileBg isWhite={isWhite} />}

      <StyledCrossWrapper
        isWhite={isWhite}
        isMenuMobileOpen={isMenuMobileOpen}
        onClick={() => dispatch(openMenuMobile())}
      >
        <StyledRightCross className={isMenuMobileOpen ? "visible" : "hidden"} />
        <StyledCross isWhite={isWhite} isMenuOpen={isMenuMobileOpen} />
        <StyledLeftCross className={isMenuMobileOpen ? "visible" : "hidden"} />
      </StyledCrossWrapper>

      <StyledModuleWrapper>
        <StyledContentWrapper>
          <StyledMenuWrapper>
            <Link to={CheckLanguage(activeLanguage)}>
              <StyedLogoWrapper className={isHome ? "home" : "subpage"}>
                <StyledLogo className={isWhite ? "white" : ""} src={Logo} />
              </StyedLogoWrapper>
            </Link>

            {!isHome && (
              <>
                <StyledLink
                  isWhite={isWhite}
                  pattern={aboutHover}
                  to={CheckLanguage(activeLanguage) + "about"}
                  activeClassName="active"
                  className={`about ${isWhite ? "white" : ""}`}
                  name={aboutObject?.pagename}
                >
                  <StyledMenuText
                    isWhite={isWhite}
                    className={`${isWhite ? "white" : ""}`}
                    name={aboutObject?.pagename}
                  >
                    {aboutObject?.pagename}
                  </StyledMenuText>
                </StyledLink>

                <StyledLink
                  isWhite={isWhite}
                  pattern={workHover}
                  to={CheckLanguage(activeLanguage) + "work"}
                  activeClassName="active"
                  className={`work ${isWhite ? "white" : ""}`}
                  name={workObject?.pagename}
                >
                  <StyledMenuText
                    isWhite={isWhite}
                    className={`margin ${isWhite ? "white" : ""}`}
                    name={workObject?.pagename}
                  >
                    {workObject?.pagename}
                  </StyledMenuText>
                </StyledLink>

                <StyledLink
                  isWhite={isWhite}
                  pattern={contactHover}
                  to={CheckLanguage(activeLanguage) + "contact"}
                  activeClassName="active"
                  className={`contact ${isWhite ? "white" : ""}`}
                  name={contactObject?.pagename}
                >
                  <StyledMenuText
                    isWhite={isWhite}
                    className={`margin ${isWhite ? "white" : ""}`}
                    name={contactObject?.pagename}
                  >
                    {contactObject?.pagename}
                  </StyledMenuText>
                </StyledLink>
              </>
            )}
          </StyledMenuWrapper>

          <StyledSocialMediaWrapper
            className={isMenuOpen ? "visible" : "hidden"}
          >
            {socialMediaData.map(item => {
              const name = item?.name || ""
              const link = item?.link || ""
              return (
                <StyledMenuText
                  as="a"
                  href={link}
                  target="_blank"
                  className={`social-media margin ${isWhite ? "white" : ""}`}
                >
                  {name}
                </StyledMenuText>
              )
            })}
            <ChangeLanguage languages={languages} />
          </StyledSocialMediaWrapper>
        </StyledContentWrapper>
      </StyledModuleWrapper>
    </>
  )
}

export default Menu
