const CheckLanguage = language => {
  switch (language) {
    case "en":
      return "/"
      break

    case "pl":
      return "/pl/"
      break

    case "es":
      return "/es/"
      break
  }
}

export default CheckLanguage
