export const Theme = {
  colors: {
    green: "#81EFDA",
  },

  fontWeight: {
    light: "300",
    regular: "400",
    medium: "500",
    semiBold: "600",
    bold: "600",
  },

  device: {
    mobileS: `(max-width: 319.97px)`,
    mobileM: `(max-width: 374.97px)`,
    mobileL: `(max-width: 424.97px)`,
    mobileXL: `(max-width: 559.97px)`,
    tablet: `(max-width: 767.97px)`,
    laptopS: `(max-width: 1023.97px)`,
    laptopM: `(max-width: 1279.97px)`,
    laptopL: `(max-width: 1439.97px)`,
    desktop: `(max-width: 1919.97px)`,
    desktopL: `(max-width: 2559.97px)`,
  },
}
