import { createGlobalStyle } from "styled-components"
import ChineseRocksRg from "../assets/fonts/ChineseRocksRg.woff2"
import LatoLight from "../assets/fonts/LatoLight.woff2"
import LatoRegular from "../assets/fonts/LatoRegular.woff2"
import LatoBold from "../assets/fonts/LatoBold.woff2"
import YrsaRegular from "../assets/fonts/YrsaRegular.woff2"
import YrsaSemiBold from "../assets/fonts/YrsaSemiBold.woff2"
import YrsaBold from "../assets/fonts/YrsaBold.woff2"

const GlobalStyle = createGlobalStyle`
  /* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900');
  @import url('https://fonts.googleapis.com/css2?family=Yrsa:wght@300;400;500;600;700'); */

  


  @font-face {
      font-family: 'Lato';
      font-display: block;
      font-style: normal;
      font-weight: 300;
      src: url(${LatoLight}) format('opentype');
  }

  @font-face {
      font-family: 'Lato';
      font-display: block;
      font-style: normal;
      font-weight: 400;
      src: url(${LatoRegular}) format('opentype');
  }

  @font-face {
      font-family: 'Lato';
      font-display: block;
      font-style: normal;
      font-weight: 600;
      src: url(${LatoBold}) format('opentype');
  }

  @font-face {
      font-family: 'Yrsa';
      font-display: block;
      font-style: normal;
      font-weight: 500;
      src: url(${YrsaRegular}) format('opentype');
  }

  @font-face {
      font-family: 'Yrsa';
      font-display: block;
      font-style: normal;
      font-weight: 600;
      src: url(${YrsaSemiBold}) format('opentype');
  }

  @font-face {
      font-family: 'Yrsa';
      font-display: block;
      font-style: normal;
      font-weight: 700;
      src: url(${YrsaBold}) format('opentype');
  }


  @font-face {
      font-family: 'ChineseRocks';
      font-display: block;
      font-style: normal;
      font-weight: 400;
      src: url(${ChineseRocksRg}) format('opentype');
  }


  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior:smooth;
    overflow:hidden;
    font-family:'Lato', 'Yrsa', sans-serif;
    height: -webkit-fill-available;
    max-height: 100vh;
  @media (max-width: 1023px) {
    max-height: unset;
    overflow-y:auto;
  }

    
  }

  body {
    font-family:'Lato', 'Yrsa', sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: -webkit-fill-available;
    max-height: 100vh;
  @media (max-width: 1023px) {
    max-height: unset;
  }

    
  }

  ::-webkit-scrollbar {
  	width: 8px;
    border-radius: 100px;
	  background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #ddd;
  }

p{
  margin:0
}

p::selection {
  color:white;
  background: #81EFDA;
}
p::-moz-selection { /* dla Firefox */
    color: white;
    background: #81EFDA;
}

div::selection {
  color:white;
  background: #81EFDA;
}
div::-moz-selection { /* dla Firefox */
    color: white;
    background: #81EFDA;
}

a::selection {
  color:white;
  background: #81EFDA;
}
a::-moz-selection { /* dla Firefox */
    color: white;
    background: #81EFDA;
}

h1::selection {
  color:white;
  background: #81EFDA;
}
h1::-moz-selection { /* dla Firefox */
    color: white;
    background: #81EFDA;
}

h2::selection {
  color:white;
  background: #81EFDA;
}
h2::-moz-selection { /* dla Firefox */
    color: white;
    background: #81EFDA;
}

h3::selection {
  color:white;
  background: #81EFDA;
}
h3::-moz-selection { /* dla Firefox */
    color: white;
    background: #81EFDA;
}

h4::selection {
  color:white;
  background: #81EFDA;
}
h4::-moz-selection { /* dla Firefox */
    color: white;
    background: #81EFDA;
}

a{
  text-decoration:none;
  color:unset;
  position: relative;
}

.fancy-text{
  font-family: "ChineseRocks";
}

.carousel .control-dots .dot {
  background-color: white;
  opacity:1;
}

.carousel .control-dots .dot.selected {
  background-color: #81EFDA;
  opacity:1;
}

#home-vivus, #work-vivus, #about-vivus, #contact-vivus{
display:flex;
}

.carousel .control-prev.control-arrow:before{
  /* border:1px solid  #81EFDA; */
  /* background-color:transparent; */
  opacity:1;
}

.carousel.carousel-slider .control-arrow:hover {
background: none;
}
`

export default GlobalStyle
