import * as React from "react"
import styled from "styled-components"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import globalContact from "../../assets/global-contact.svg"

const Text = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Lato";
  pointer-events: all;
  &.ml-20 {
    margin-left: 20%;
    @media (max-width: 450px) {
      margin-left: 0%;
    }
  }
  &.contact {
    color: white;
    margin-top: 16px;
    margin-right: 25%;
    position: relative;
    @media (max-width: 1200px) {
      margin-right: 0px;
    }
    @media (max-width: 500px) {
      margin-top: 20px;
    }
    &:hover {
      &::after {
        content: "${({ textHover }) => textHover}";
        position: absolute;
      }
    }
    .fancy-text {
      font-family: "ChineseRocks";
      font-size: 41px;
      color: ${({ theme }) => theme.colors.green};
      text-decoration: line-through;
      @media (max-width: 1023px) {
        font-size: 18px;
      }
    }
    .global-contact {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 36px;
        width: 207px;
        top: -5px;
        right: 10px;
        background-image: url(${globalContact});
        @media (max-width: 500px) {
          top: -8px;
        }
      }
    }
    .line {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 100%;
        top: 12px;
        left: 0;
        background-color: ${({ theme }) => theme.colors.green};
        @media (max-width: 1023px) {
          top: 9px;
        }
      }
    }
  }
  a {
    &.about {
      position: relative;
      text-decoration: line-through;
      &:hover {
        &::after {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          content: "vividdesignart.com";
          font-family: "ChineseRocks";
          font-size: 41px;
          background-color: white;
          @media (max-width: 1023px) {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (max-width: 850px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const Paragraph = ({ children, className, textHover }) => (
  <Text
    textHover={textHover}
    className={className}
    dangerouslySetInnerHTML={getHtmlFromRedactorField(children)}
  />
)

export default Paragraph
