import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { openMenu, openMenuMobile } from "../../store/menuSlice"
import { useSelector, useDispatch } from "react-redux"
import Logo from "../../assets/logo.svg"
import signature from "../../assets/signature.png"
import LogoHover from "../../assets/logo-hover.svg"
import ChangeLanguage from "../ChangeLanguage"
import CheckLanguage from "../../utils/checkLangauge"

const ModuleWrapper = styled.div`
  background-color: #e3e3e3;
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  min-height: -webkit-fill-available;
`

const StyledRectangle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 483px;
  height: 483px;
  position: absolute;
  border: 2px solid white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  pointer-events: none;
  margin: auto;
  &.visible {
  }
  @media (max-width: 1443px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 1024px) {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 850px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 280px;
  }
`

const StyledPattern = styled.img`
  width: 483px;
  height: 483px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  pointer-events: none;
  margin: auto;
  transform: scale(0.95);
  transition: opacity 0.3s ease-in-out;
  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
  @media (max-width: 1443px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 1024px) {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 850px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 280px;
  }
`

const StyledMenuWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50px;
  bottom: 0;
  margin: auto;
  z-index: 103;
  height: auto;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  @media (max-width: 500px) {
    top: 0;
    height: auto;
    flex-direction: column;
    width: auto;
    height: max-content;
    left: 0px;
  }
`

const StyledMenuText = styled.h3`
  font-family: "Lato";
  font-size: 14px;

  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  transition: transform 0.3s ease-in-out;
  position: relative;
  color: black;
  margin: 0 32px;

  @media (max-width: 500px) {
    margin: 0;
    line-height: 70px;
  }

  &::after {
    content: "${({ name }) => name}";
    position: absolute;
    color: #eee;
    transform: translateY(0px);
    left: 0;
    right: 5px;
    margin: 0 auto;
    z-index: -1;
  }
  &::before {
    content: "${({ name }) => name}";
    position: absolute;
    color: ${({ theme }) => theme.colors.green};
    transform: translateY(0px);
    left: 0;
    right: 5px;
    margin: 0 auto;
    z-index: -1;
  }
  &.social-media {
    margin: 0 16px;
    font-size: 12px;
    text-transform: lowercase;
  }
  /* 
  &.margin {
    margin-left: 80px;

    @media (max-width: 1023px) {
      margin-top: 65px;
      margin-left: 40px;
    }
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
  &.margin-rwd {
    @media (max-width: 1023px) {
      margin-top: 65px;
      margin-left: 40px;
    }
    @media (max-width: 500px) {
      margin-top: 20px;
      margin-left: 0px;
    }
  } */

  /* &:hover {

    &::after {
      transform: translateY(-7px);
    }
    &::before {
      transform: translateY(7px);
    }
  } */
`

const StyledLogo = styled.img`
  width: 34px;
  height: auto;
  &.white {
    filter: invert(100%) sepia(0%) saturate(7482%) hue-rotate(97deg)
      brightness(94%) contrast(105%);
  }
`

const StyedLogoWrapper = styled.div`
  position: fixed;
  z-index: 101;
  top: 40px;
  left: 40px;
  width: 34px;
  height: 34px;
`

const StyledSignature = styled.img`
  width: 200px;
  height: auto;
  z-index: 11;
  transition: transform 0.3s ease-in-out;
  /* animation: ${signature} 0.7s 0.6s ease-in-out both; */
  transform: ${({ isMenuOpen }) => (isMenuOpen ? "scale(0.9)" : "scale(1.0)")};
  @media (max-width: 1443px) {
    width: 116px;
  }
  @media (max-width: 1024px) {
    right: 0px;
    width: 162px;
  }
  @media (max-width: 850px) {
    width: 100px;
  }
  @media (max-width: 426px) {
    width: 90px;
  }
`

const StyledLogoBottom = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 3%;
`

const StyledSocialMediaWrapper = styled.div`
  pointer-events: all;
  display: flex;
  transition: opacity 0.5s ease-in-out;
  transform: rotate(-90deg);
  position: absolute;
  z-index: 100;
  @media (max-width: 1024px) {
    right: -60%;
  }

  @media (max-width: 768px) {
    right: -65%;
  }

  @media (max-width: 500px) {
    right: -63%;
  }
`

const MenuMobile = ({ data, globalData, isHome, isWhite }) => {
  const isMenuOpen = useSelector(state => state.menu.isMenuOpen)
  const isMenuMobileOpen = useSelector(state => state.menu.isMenuMobileOpen)
  const activePatternMenu = useSelector(state => state.menu.activePatternMenu)
  const activeLanguage = useSelector(state => state.menu.activeLanguage)

  const dispatch = useDispatch()

  const socialMediaData = globalData?.socialmedia || []
  const pagesData = data?.menuitem || []
  const languages = globalData?.languages || ""

  let linkArray = []
  let aboutObject = ""
  let workObject = ""
  let contactObject = ""

  pagesData.map(item => {
    const pageLinkArray = item?.pagelink || []
    return (linkArray = linkArray.concat(pageLinkArray))
  })

  linkArray.map(item => {
    switch (item?.__typename) {
      case "DatoCmsAbout":
        aboutObject = item
        break
      case "DatoCmsWork":
        workObject = item
        break
      case "DatoCmsContact":
        contactObject = item
        break
    }
  })

  //   const [activePattern, setActivePattern] = useState("")

  //   const activeMenuPageHandler = page => {
  //     const pattern = page?.menupattern?.url || ""
  //     setActivePattern(pattern)
  //   }

  return (
    <ModuleWrapper>
      <Link
        to={CheckLanguage(activeLanguage)}
        onClick={() => dispatch(openMenuMobile())}
      >
        <StyedLogoWrapper className={isHome ? "home" : "subpage"}>
          <StyledLogo src={Logo} />
        </StyedLogoWrapper>
      </Link>
      <StyledMenuWrapper>
        <Link
          to={CheckLanguage(activeLanguage) + "about"}
          state={{ fromHome: false }}
        >
          <StyledMenuText
            onClick={() => dispatch(openMenuMobile())}
            name={aboutObject?.pagename}
          >
            {aboutObject?.pagename}
          </StyledMenuText>
        </Link>

        <Link
          to={CheckLanguage(activeLanguage) + "work"}
          state={{ fromHome: false }}
        >
          <StyledMenuText
            onClick={() => dispatch(openMenuMobile())}
            name={workObject?.pagename}
          >
            {workObject?.pagename}
          </StyledMenuText>
        </Link>

        <Link
          to={CheckLanguage(activeLanguage) + "contact"}
          state={{ fromHome: false }}
        >
          <StyledMenuText
            onClick={() => dispatch(openMenuMobile())}
            name={contactObject?.pagename}
          >
            {contactObject?.pagename}
          </StyledMenuText>
        </Link>
      </StyledMenuWrapper>

      <StyledRectangle>
        {isHome && <StyledSignature src={signature} />}

        <StyledPattern src={activePatternMenu} />
        <StyledSocialMediaWrapper>
          {socialMediaData.map(item => {
            const name = item?.name || ""
            const link = item?.link || ""
            return (
              <StyledMenuText
                as="a"
                href={link}
                target="_blank"
                className={`social-media margin ${isWhite ? "white" : ""}`}
              >
                {name}
              </StyledMenuText>
            )
          })}
          <ChangeLanguage languages={languages} />
        </StyledSocialMediaWrapper>
      </StyledRectangle>

      <StyledLogoBottom src={LogoHover} />
    </ModuleWrapper>
  )
}

export default MenuMobile
